@import url('https://fonts.googleapis.com/css2?family=Dongle:wght@300;400;700&family=Noto+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,700;1,100;1,300;1,400;1,500;1,600&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;1,100;1,200;1,300;1,400;1,500;1,600&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,500;1,700;1,900&display=swap');
html {
    scroll-behavior: smooth;
}

body {
    font-family: "Roboto", sans-serif;
    color: #777;
    line-height: 1.7em;
    font-weight: 400;
    background: #fff;
    overflow-x: hidden;
}

::-webkit-scrollbar {
    width: 0;
}

.TextInputStyle {
    border: solid 1px lightgray;
    border-radius: 5px;
    margin-bottom: 10px;
    padding: 5px 0px 5px 10px;
    background-color: white;
    position: relative;
}

.mb-80 {
    margin-bottom: 80px;
}

.mt-80 {
    margin-top: 80px;
}

.ptb-80 {
    padding: 80px 0;
}

.mtb-80 {
    margin: 80px 0;
}

.pt-80 {
    padding-top: 80px;
}

.pb-80 {
    padding-bottom: 80px;
}

.box-shadow {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1882352941);
}

.box-shadow-2 {
    box-shadow: 0 0 15px rgba(255, 255, 255, 0.4);
}

.right-0 {
    right: 0;
}

.pt-10 {
    padding-top: 10pc;
}

.mt-10 {
    margin-top: 10pc;
}

.btn:focus {
    box-shadow: none;
}

.btn {
    padding: 10px 30px;
    margin-top: 1pc;
    font-weight: 500 !important;
}

.btn-1 {
    border: 1px solid #d35356;
}

p {
    color: #252525;
    font-weight: 400;
    line-height: 1.8em;
}

p:last-child {
    margin-bottom: 0;
}

.red {
    color: #D35356 !important;
}

.red-bg {
    background-color: #D35356 !important;
}

.blue-bg {
    background-color: #2D3E50 !important;
}

.blue {
    color: #2D3E50 !important;
}

.gray-bg {
    background-color: #efefef;
}

a:hover {
    color: #D35356;
    transition: all 0.5s;
}

ul {
    margin: 0;
    padding: 0;
}

ul.list-circle li {
    list-style-type: circle !important;
    list-style-position: inside !important;
}

ul li {
    list-style: none;
    color: #252525;
}

.btn-sm {
    font-size: 0.875rem !important;
    padding: 7px 22px !important;
    border-radius: 0.2rem;
}

.main-logo {
    height: auto;
    width: 110px;
    -o-object-fit: contain;
    object-fit: contain;
    transition: all 0.5s;
}

.img-fluid {
    height: 100%;
    width: 100%;
    -o-object-fit: cover;
    object-fit: cover;
}

.title-section {
    margin-bottom: 3pc;
}

img.client-img {
    height: 80px;
    -o-object-fit: contain;
    object-fit: contain;
}

a:not(.btn):hover {
    color: #D35356;
    transition: all 0.5s;
}

.heading {
    text-transform: capitalize;
}

.heading.bottom-border {
    position: relative;
    padding-bottom: 1pc;
}

.heading.bottom-border:before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    height: 3px;
    width: 65%;
    background-color: #D35356;
}

.form [class*=col-] {
    margin-bottom: 1.5pc;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.form [class*=col-]:last-child {
    margin-bottom: 0;
}

.form .form-check:not(.form-check-inline) {
    display: flex;
    gap: 10px;
    align-items: center;
}

.form .form-check.form-check-inline .form-check-input {
    margin-right: 10px;
}

.form .form-check input[type=checkbox] {
    width: 20px;
    height: 20px;
    background-size: 20px auto !important;
    border-radius: 0 !important;
    -webkit-appearance: none;
    cursor: pointer;
    transition: all 0.5s;
}

.form .form-check input[type=checkbox].form-check-input:focus {
    border-color: rgba(0, 0, 0, 0.2509803922);
    box-shadow: none;
}

.form .form-check input[type=checkbox]:checked {
    background-size: 22px;
    background-color: #f06123;
    border-color: #f06123;
    transition: all 0.5s;
}

.form .select2-container--default {
    width: 100% !important;
}

.form .select2-container--default .select2-selection--single {
    height: 40px !important;
    border-radius: 0;
    width: 100%;
    padding: 0.375rem 0.75rem;
    background-color: #fff;
    border: 1px solid #ced4da !important;
}

.form .select2-container--default .select2-selection--single .select2-selection__arrow {
    height: 40px !important;
}

.form textarea.form-control {
    height: 102px !important;
}

.form .form-control {
    height: 40px;
    border-radius: 0;
}

.form .form-control:focus {
    border-color: #2D3E50;
    box-shadow: none;
    transition: all 0.5s;
}

.form .form-control:focus::-moz-placeholder {
    opacity: 0;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}

.form .form-control:focus:-ms-input-placeholder {
    opacity: 0;
    -ms-transition: all 0.5s;
    transition: all 0.5s;
}

.form .form-control:focus::placeholder {
    opacity: 0;
    transition: all 0.5s;
}

.form .form-control::-moz-placeholder {
    color: #000;
    text-transform: capitalize;
}

.form .form-control:-ms-input-placeholder {
    color: #000;
    text-transform: capitalize;
}

.form .form-control::placeholder {
    color: #000;
    text-transform: capitalize;
}

.form label {
    font-size: 15px;
    margin-bottom: 5px;
    color: #000;
    line-height: 20px;
}

.faculty-banner {
    background-image: url("./Asset/images/faculty.png");
    background-size: cover;
    box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.4);
    height: 60vh;
    display: flex;
    align-items: center;
    justify-content: center;
}


.our-faculty li {
    margin-bottom: 4pc;
}

.our-faculty li img {
    height: 200px;
    width: 200px;
    -o-object-fit: cover;
    object-fit: cover;
}

.our-faculty li:nth-child(even) {
    flex-direction: row-reverse;
}

section.header {
    z-index: 44;
}

.logo {
    width: max-content;
    background-color: #ffffff66;
    padding: 1pc 3pc;
}

.flexRow {
    display: flex;
    flex-direction: row;
}

.flexColumn {
    display: flex;
    flex-direction: column;
}

.contact-form-left {
    background-color: #efefef;
    padding: 2pc;
    border: 1px solid lightgray;
    position: relative;
}

.main_form_sec {
    background-image: url(../src/Asset/images/about_bg.png);
    background-position: right top;
    background-size: contain;
    background-repeat: no-repeat;
}

.water_mark {
    background-image: url(../src/Asset/images/logo.png);
    background-position: center;
    background-size: 30%;
    background-repeat: no-repeat;
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 0;
    opacity: .2;
    z-index: 0;
    pointer-events: none;
}



.buttons:hover {
    background-color: #C23135;
    color: #FFFFFF;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    transition: .4s;
    outline: none;
}

.buttons {
    height: 43px;
    width: 102px;
    margin-top: 10px;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    font-style: normal;
    line-height: 19px;
    color: white;
    border: 1px solid #C23135;
    font-size: 16px;
    background-color: #c23135;
}

.message {
    border: 4px dotted #ffffff;
    border-radius: 10px;
    background: #C23135;
    color: white;
}

.partners_img {
    background-color: #FAFAFA;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 2pc;
    height: 170px;
}

.partners_img img {
    height: 100%;
    width: 100%;
    object-fit: contain;
    box-shadow: 0 0 10px #00000030;
}

.our_partners .col {
    flex-basis: 20%;
    max-width: 20%;
}


.form_text {
    font-size: 16px;
    color: gray;
}

.logo img {
    width: 100px;
}

.copyright {
    background-color: #202020;
    border-top: 1px solid rgba(0, 0, 0, 0.1803921569);
    padding: 2pc 0;
    text-align: center;
}

.copyright p {
    color: #ffffff80;
    margin: 0;
}

.sample_btn {
    top: 0px;
    right: 0px;
    height: 100%;
    width: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #dc3545;
    color: white;
    cursor: pointer;
}

.hov_div {
    width: 200px;
    height: 200px;
    box-shadow: 0 0 22px #00000030;
    border: 1px solid lightgray;
    right: 0px;
    background-color: #efefef;
}

.hov_div img {
    height: 100%;
    width: 100%;
    object-fit: contain;
}

@media (max-width: 576px){
    .faculty-banner{
        height: 80vh;
    }
    .our_partners .col {
        flex-basis: 50%;
        max-width: 50%;
    }
    .water_mark{
        background-size: 50%;
    }
}
@media (min-width: 576px) and (max-width: 767px) {
    .our_partners .col {
        flex-basis: 50%;
        max-width: 50%;
    }
}
@media (min-width: 767px) and (max-width: 992px) {
    .our_partners .col {
        flex-basis: 25%;
        max-width: 25%;
    }
}
